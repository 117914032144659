import React, { useState } from "react"

import Sizing from "./Sizing"
import styling from "../../styles/heading.module.scss"

const Heading = ({ children, navy = false, circle = true, langKey = 'en' }) => {
    let classStr = navy ? "heading--navy" : ""
    let circleClass = langKey == 'en' ? styling.mieCircle : styling.mieCircle_es

    const getCircle = () => {
        if (!circle) return
        return (
            <Sizing
                styles={{
                    position: "absolute",
                }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            width: 5,
                            height: 5,
                            left: -2.6,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            width: 2.5,
                            height: 2.5,
                            left: -1.3,
                        },
                    },
                ]}
            >
                <div className={circleClass}></div>
            </Sizing>
        )
    }
    return (
        <>
            <div className={styling.main}>
                {getCircle()}

                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                paddingTop: circle ? 2.8 : 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                paddingTop: circle ? 1.4 : 0,
                            },
                        },
                    ]}
                >
                    <span className={styling.content + " heading " + classStr}>
                        {children}
                    </span>
                </Sizing>
            </div>
        </>
    )
}

export default Heading
