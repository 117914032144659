import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import DiseaseGraph from "@/components/graphs/DiseaseGraph"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"
import pullquote from "@/images/rents/pullquote.svg"
import angle from "@/images/angle.svg"
import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer"
import Share from "@/components/general/Share"

import openerText from "@/images/disease/opener-text.png"
import openerTextSml from "@/images/disease/opener-text-sml.png"
import shareImg from "@/images/og/og_disease.jpg"

import dce from "@/images/disease/dce.png"
import dcg from "@/images/disease/dcg.png"

import SignupPopup from "@/components/general/SignupPopup"

const DiseasePage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/disease"
                title="Free Markets Destroy | Learn How Free Markets Destroy Disease"
                description="Even critics of markets will usually concede that competition and innovation improve our lives. But do free markets really make us healthier?"
                img={shareImg}
            />

            <Opener
                pageName="disease"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "right" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            // marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className="copy--lead">
                    <Dropcap img={dce}>E</Dropcap>ven critics of markets will
                    usually concede that competition and innovation improves our
                    lives. Free markets lift people out of poverty by providing
                    incentives—typically income, benefits and social capital—for
                    hard work and service. But as millions of workaholic
                    Millennials fighting burnout will attest, being well-off
                    isn’t the same thing as being well. Sure, free markets gave
                    us Peloton and keto pizza, but do they really make us
                    healthier?
                </div>
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 14,
                            // marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <Image
                    src={"disease_advance"}
                    style={{ width: "100%" }}
                ></Image>
            </Sizing>

            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div>

            <Sizing
                styles={{ float: "left", clear: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 6,
                            marginTop: -2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 4,
                            marginTop: -2,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <span className="caption">
                    - In the 19th Century, this was exercise
                </span>
            </Sizing>
            <Sizing
                styles={{ float: "right", position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            marginTop: 1,
                            marginBottom: 2,
                        },
                    },
                    {
                        min: 1200,
                        val: {
                            marginRight: 4,
                            width: 8,
                            marginTop: 1,
                            marginBottom: 2,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <p className="copy">
                    The answer is unambiguously yes. Free markets have already
                    helped humanity conquer some of our most daunting health and
                    wellness challenges, connecting billions of people with
                    affordable food, clean drinking water, and life-saving
                    medical equipment. They provided us with hours of daily free
                    time for rest, exercise, and socializing. Since the
                    publication of Adam Smith’s The Wealth of Nations in 1776
                    and the rise of free market capitalism in Europe and
                    America, global life expectancy has gone from 29 years to
                    more than 70.
                </p>

                <p className="copy">
                    Take a moment to let that sink in. In 250 years—an eye blink
                    in the grand scheme of human history—the innovation,
                    competition, and choice enabled by free markets gave us 40
                    extra years of life. That’s a lot of extra candles on a
                    (vegan, sugar-free) birthday cake.
                </p>

                <Sizing
                    styles={{ position: "absolute" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                bottom: -2,
                                right: -2,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="disease" id="motif_8" movement={-0.1} />
                </Sizing>
            </Sizing>

            <Sizing
                styles={{ float: "left", position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            marginTop: 2,
                            width: 3,
                        },
                    },
                ]}
            >
                <Motif page="disease" id="motif_9" movement={0.2} />
            </Sizing>

            <DiseaseGraph></DiseaseGraph>

            <Sizing
                styles={{ float: "right" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            marginTop: 0,
                        },
                    },
                    {
                        min: 1200,
                        val: {
                            marginRight: 4,
                            width: 8,
                            marginTop: 0,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <p className="copy--lead">
                    Of course, free markets are nothing more than a few basic
                    rules and protections that help us serve one another. As the
                    free-market advocate Leonard E. Read liked to say, a market
                    is simply “anything that’s peaceful.” The real heroes of any
                    free market system are the individuals and teams who
                    dedicate their lives to solving problems. Individuals like
                    Dr. Maurice Hilleman.{" "}
                </p>
                <p className="copy">
                    Often called the father of modern vaccines, Hilleman spent
                    the majority of his career working at Bristol-Myers Squibb
                    and Merck, two of the world’s most influential and
                    life-saving pharmaceutical companies. By the time he retired
                    at the age of 65, Hilleman had prevented pandemic flu,
                    created the combined measles-mumps-rubella (MMR) vaccine,
                    and developed the world’s first vaccine against cancer.
                    Today, more than 30 years after retiring from Merck,
                    Hilleman’s work continues to save an estimated eight million
                    lives every year, primarily through the MMR vaccine which is
                    still owned and produced by his former employer, Merck.{" "}
                </p>

                <p className="copy">
                    Dr. Hilleman was a brilliant scientist, but he was also an
                    iconoclastic rule breaker. In 1963, when his daughter Jeryl
                    Lynn came down with the classic symptoms of mumps, Hilleman
                    swabbed the back of her throat and used the virus sample to
                    create the first prototype mumps vaccine. To this day, the
                    viral strain used to produce the MMR vaccine is called the
                    Jeryl Lynn strain. Many of Dr. Hilleman’s admirers,
                    including Dr. Anthony Fauci, have commented that Hilleman’s
                    idiosyncratic style would be unlikely to produce such
                    amazing results in today’s strict regulatory environment.{" "}
                </p>
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 1,
                            width: 22,
                            marginTop: -0.8,
                        },
                    },
                    {
                        min: 1224,
                        val: {
                            marginLeft: 1,
                            width: 10,
                            marginTop: 2,
                            paddingBottom: 0.3,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <Image src="disease_illu2" style={{ width: "100%" }}></Image>
            </Sizing>

            <Sizing
                styles={{ float: "left", position: "relative" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 15,
                            marginTop: -0.8,
                            marginBottom: 3,
                        },
                    },
                    {
                        min: 1224,
                        val: {
                            marginLeft: 1,
                            width: 8,
                            marginTop: -0.8,
                            marginBottom: 0,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <span className="caption">
                    - Dr. Maurice Hilleman vaccinates his daughter in the 1960s.
                </span>

                <Sizing
                    styles={{ position: "absolute" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                left: 4,
                                bottom: -4,
                                width: 1.5,
                            },
                        },
                    ]}
                >
                    <Motif page="disease" id="motif_1" movement={-0.1} />
                </Sizing>
            </Sizing>

            <ColorChangeBG
                from={{ r: 54, g: 153, b: 221 }}
                to={{ r: 242, g: 174, b: 187 }}
            >
                <div
                    style={{
                        width: "100%",
                        paddingBottom: "10%",
                        backgroundImage: `url(${angle})`,
                        backgroundSize: "100% 100%",
                    }}
                ></div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginTop: 1,
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 8,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 5,
                                width: 8,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <p className="copy">
                        Impatient and entrepreneurial, Dr. Hilleman believed
                        that academic institutions and government agencies
                        lacked the tenacity to bring scientific innovations to
                        market, where they would do the most good. That’s why he
                        spent his career working for large corporations with
                        deep pockets and a serious commitment to innovation.
                        Hilleman maintained that scientists had a responsibility
                        to provide a financial return on knowledge gained in the
                        laboratory so that companies like Merck would continue
                        to fund expensive research projects with the potential
                        to transform the world.
                    </p>

                    <p className="copy">
                        And if you’ve never even heard of mumps—well, you have
                        Dr. Hilleman and free markets to thank.
                    </p>

                    <p className="copy">
                        One final note: In addition to corporations like Squibb
                        and Merck, free markets also give us massive endowed
                        charities like the Bill & Melinda Gates Foundation and
                        the Howard Hughes Medical Institute. These
                        organizations, founded by famous capitalists, are doing
                        as much as any private company or government agency to
                        help humanity conquer disease. And they are, in the
                        purest sense, products of the free market, conceived and
                        launched by entrepreneurs looking to solve the problems
                        plaguing humanity.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 4,
                                width: 18,
                                marginBottom: 3,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 4,
                                marginTop: 2,

                                minWidth: "350px",
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Signup color={"#e99245"}></Signup>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 1,
                                marginRight: 4,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="disease" id="motif_3" movement={-0.1} />
                </Sizing>
                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                // marginBottom: 1,
                                paddingBottom: 0.3,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 4,
                                width: 16,
                                marginTop: 2,
                                paddingBottom: 0.3,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -3,
                                    top: -2,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_2" movement={0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -3,
                                    bottom: 0,
                                    width: 1.5,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_10"
                            movement={0.1}
                            rotation={-0.1}
                        />
                    </Sizing>

                    <Image src="disease_lab" style={{ width: "100%" }}></Image>
                </Sizing>
                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 4,
                                width: 16,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <span className="caption">
                        - Thousands of researchers are working around the clock
                        to discover the next great vaccine.
                    </span>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 12,
                                marginTop: 4,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: 4,
                                    top: -3,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_9" movement={0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -6,
                                    top: -1,
                                    width: 2.5,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="cover_07" movement={-0.1} />
                    </Sizing>

                    <Heading navy="true">23andMe and You and Everyone</Heading>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 9,
                                marginTop: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className={"copy--lead"}>
                        <Dropcap img={dcg}>G</Dropcap>
                        enetic-testing company 23andMe does more than trace your
                        ancestry. The company arms customers with life-saving
                        information about their predisposition to heart disease,
                        cancer, diabetes, and other noncommunicable diseases.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 14,
                                marginTop: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Image
                        src="disease_illu1"
                        style={{ width: "100%" }}
                    ></Image>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -2,
                                    top: 3,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_4"
                            movement={-0.1}
                            rotation={0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -7,
                                    top: 3,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_5"
                            movement={-0.1}
                            rotation={-0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -2.5,
                                    bottom: 3,
                                    width: 0.9,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_7"
                            movement={-0.2}
                            rotation={-0.2}
                        />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginTop: 1,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 9,
                                marginTop: 1,
                                marginBottom: 2,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -2,
                                    top: 3,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_5"
                            movement={-0.1}
                            rotation={0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -7,
                                    top: 3,
                                    width: 0.9,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_7"
                            movement={-0.1}
                            rotation={-0.1}
                        />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    left: -4,
                                    top: 5,
                                    width: 0.8,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="disease"
                            id="motif_4"
                            movement={-0.2}
                            rotation={-0.2}
                        />
                    </Sizing>

                    <p className="copy">
                        For example, a 23andMe test can tell you if you have a
                        genetic marker that signals a higher than average risk
                        of breast cancer. This information helps 23andMe’s
                        customers make smarter decisions to protect their
                        health, whether that means drinking less, modifying
                        their diet, or taking preventative medicine.
                    </p>
                    <p className="copy">
                        The company also provides opportunities for customers to
                        participate in clinical trials for new life-saving
                        interventions. This accelerates the pace of scientific
                        discovery while helping individuals take control of
                        their own health and wellness journeys. With over $786
                        million in funding over the last few years, 23andMe is
                        still growing, exploring what is possible in a rapidly
                        evolving medical marketplace. And their story is just
                        one more example of how markets power the innovators who
                        will produce the next great scientific breakthrough.
                    </p>
                </Sizing>
                <Spacing space="0" />
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: 20,
                                maxWidth: "300px",
                                height: "50px",
                                marginTop: 2,
                                paddingBottom: 5,
                                position: "relative",
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "none",
                            },
                        },
                    ]}
                >
                    <Share></Share>
                </Sizing>
            </ColorChangeBG>
            <Spacing space="0" />
            <FooterNav next={"hunger"} previous={"racism"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default DiseasePage
